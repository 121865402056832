import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  MDBInput,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTableHead,
  MDBTable,
  MDBTableBody,
  MDBSelect,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBadge,
  MDBTabContent,
  MDBTabPane,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
} from "mdbreact";
import { Checkbox } from "antd";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { getRecurringBookings } from "../../../actions/recurringAction";
import BookingEditor from "../shared/BookingEditor";
import {
  cancelRecurring,
  reprocessRecurring,
  getRecurringById,
  unblockRecurringSchedule,
} from "../../../apis/RecurringApi";

import {
  getBookings,
  getBookingDetails,
  getStatuses,
} from "../../../actions/bookingAction";

import {
  displayBreadcrumbs,
  showHideLoader,
  toggleModal,
  showNotification,
} from "../../../actions";
import _ from "lodash";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { validateAccess } from "../../../helper/utils";
import { getResource } from "../../../actions/resourceAction";

class ManageRecurring extends React.Component {
  state = {
    resultContainer: {},
    startDateFilter: "",
    endDateFilter: "",
    limitOptions: [],
    statusOptions: [],
    limit: "10",
    status: "",
    query: "",
    page: "1",
    activeItemOuterTabs: "1",
    activeItemInnerPills: "1",
    showBookingDetailsModal: false,
    loader: true,
    filterLoaded: false,
    total_bookings: 0,
    recurringSchedule: {},
    recurringCustomer: {},
    recurringData: {},
    statuses: [],
    selectedBooking: {},
    selectedCancelSchedules: [],
    unblockTimeSlotStartDate: "",
    unblockTimeSlotEndDate: "",
    modalNav: "blocked_dates_nav",
  };

  debouncedDoFilter = _.debounce(function () {
    this.doFilter();
  }, 10);

  initLimitOptions() {
    let options = [
      {
        checked: false,
        disabled: false,
        text: "5",
        value: "5",
      },
      {
        checked: true,
        disabled: false,
        text: "10",
        value: "10",
      },
      {
        checked: false,
        disabled: false,
        text: "20",
        value: "20",
      },
      {
        checked: false,
        disabled: false,
        text: "25",
        value: "25",
      },
    ];

    this.setState({ limitOptions: options });
  }

  endDateChangeHandler = (date) => {
    this.setState({
      endDate: date,
    });
  };

  componentDidMount() {
    this.props.showHideLoader(true);

    this.props.displayBreadcrumbs(
      "Dashboard / Administrator / Report / Recurring Bookings"
    );

    let params = {};

    this.initLimitOptions();
    this.initStatusOptions();

    this.props.getStatuses(this.props.authUser.sessionToken);
    this.props.getRecurringBookings(params, this.props.authUser.sessionToken);
  }

  render() {
    return (
      <div>
        {this.renderForm()}
        {this.renderTable()}
        {this.renderBookingDetailsForm()}
      </div>
    );
  }

  toggleSelectedRecurringSchedule(id) {
    const { selectedCancelSchedules } = this.state;

    let currentSelectedIds = selectedCancelSchedules;
    const index = currentSelectedIds.indexOf(id);

    if (index !== -1) {
      // ID exists, remove it
      currentSelectedIds.splice(index, 1);
    } else {
      // ID does not exist, add it
      currentSelectedIds.push(id);
    }

    this.setState({
      selectedCancelSchedules: currentSelectedIds,
    });
  }

  startDateFilterOnChange = (date) => {
    this.setState({
      startDateFilter: date,
    });
    this.debouncedDoFilter();
  };

  endDateFilterOnChange = (date) => {
    this.setState({
      endDateFilter: date,
    });
    this.debouncedDoFilter();
  };

  datePicker() {
    return (
      <span>
        <DatePicker
          className="custom-input"
          placeholderText="Select Start Date"
          selected={this.state.startDateFilter}
          onChange={this.startDateFilterOnChange}
          dateFormat="dd/MM/yyyy"
        />
      </span>
    );
  }

  endDatePicker() {
    return (
      <span>
        <DatePicker
          className="custom-input"
          placeholderText="Select End Date"
          selected={this.state.endDateFilter}
          onChange={this.endDateFilterOnChange}
          dateFormat="dd/MM/yyyy"
        />
      </span>
    );
  }

  getValueOfSelectStatus = (value) => {
    this.setState({ status: value[0] });
    this.debouncedDoFilter();
  };

  renderFilters() {
    const { limitOptions, statusOptions } = this.state;

    return (
      <div>
        <MDBRow className="mb-4">
          <MDBCol md="6">
            <h3 className="mt-3">Recurring Bookings</h3>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="3">
            <MDBRow>
              <MDBCol md="12">{this.datePicker()}</MDBCol>
              <MDBCol md="12" className="mt-2">
                {this.endDatePicker()}
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol md="3">
            <MDBRow>
              <MDBCol md="12" className="remove-margin-tb">
                <MDBSelect
                  outline
                  color="primary"
                  getValue={this.getValueOfSelectStatus}
                  options={statusOptions}
                  label="Status"
                />
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="2">
            <MDBSelect
              outline
              color="primary"
              getValue={this.getValueOfSelectLimit}
              value={this.state.limit}
              options={limitOptions}
              label="Show entries"
            />
          </MDBCol>
          <MDBCol md="10">
            <div className="float-right">
              <MDBInput
                label="Search recurring by keyword(s)."
                name="query"
                onChange={this.changeHandler}
                style={{ width: "430px" }}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.recurringBookingResponse !== this.props.recurringBookingResponse
    ) {
      let recurringBookingResponse = this.props.recurringBookingResponse;
      if (recurringBookingResponse.action == "ACTION") {
        this.props.showNotification(
          recurringBookingResponse.notificationType,
          recurringBookingResponse.data.message
        );
        if (
          recurringBookingResponse.status >= 200 &&
          recurringBookingResponse.status <= 300
        ) {
          this.doFilter();
        }
        this.props.showHideLoader(false);
      } else {
        this.props.showHideLoader(false);
        this.setState({
          resultContainer: this.props.recurringBookingResponse.data,
          page: this.props.recurringBookingResponse.data.page,
        });
      }
    }

    this.bookingResponseHandler(prevProps, prevState);
    this.getStatusResponseHandler(prevProps, prevState);
    this.getResourceResponse(prevProps, prevState);
  }

  bookingResponseHandler(prevProps, prevState) {
    if (
      prevProps.bookingDetailsResponse !== this.props.bookingDetailsResponse &&
      this.props.bookingDetailsResponse
    ) {
      let bookingDetails = this.props.bookingDetailsResponse;

      this.props.getResource(
        bookingDetails.resource.uuid,
        this.props.authUser.sessionToken
      );
      this.setState({
        selectedBooking: bookingDetails,
      });
    }
  }

  getStatusResponseHandler(prevProps, prevState) {
    if (
      prevProps.getStatusesSuccessResponse !==
        this.props.getStatusesSuccessResponse &&
      this.props.getStatusesSuccessResponse.status === 200
    ) {
      this.setState({
        statuses: this.props.getStatusesSuccessResponse.data.statuses,
      });
    }
  }

  renderBookingDetailsForm() {
    const { locationResource, statuses, selectedBooking } = this.state;

    if (selectedBooking?.uuid && locationResource?.uuid) {
      return (
        <div>
          <BookingEditor
            doCalculate={true}
            statuses={statuses}
            selectedBooking={selectedBooking}
            locationResource={locationResource}
            isView={false}
          />
        </div>
      );
    } else {
      return <span></span>;
    }
  }

  getResourceResponse(prevProps, prevState) {
    if (
      prevProps.getResourceResponse !== this.props.getResourceResponse &&
      this.props.getResourceResponse.status === 200
    ) {
      this.setState({
        locationResource: this.props.getResourceResponse.data,
        showBookingDetailsModal: true,
      });
    }
  }

  showRecurringModal(showRecurringBookingModal, source) {
    if (source !== null) {
      let recurring_id = source["id"];
      this.setState({
        showRecurringBookingModal: showRecurringBookingModal,
        selectedBooking: {},
        locationResource: {},
      });

      this.doGetRecurringRecord(recurring_id);
    } else {
      this.setState({
        showRecurringBookingModal: showRecurringBookingModal,
        isLoaded: false,
        selectedCancelSchedules: [],
        selectedBooking: {},
        locationResource: {},
        recurringSchedule: {},
      });
    }
  }

  renderRecurringSchedulesStatus(status) {
    let statusDisplay = status.charAt(0).toUpperCase() + status.slice(1);

    if (["cancelling", "cancelled"].includes(status)) {
      return (
        <MDBBadge pill color="danger">
          {statusDisplay}
        </MDBBadge>
      );
    } else if (["updating", "processing"].includes(status)) {
      return (
        <MDBBadge pill color="primary">
          {statusDisplay}
        </MDBBadge>
      );
    } else if (["completed"].includes(status)) {
      return (
        <MDBBadge pill color="success">
          {statusDisplay}
        </MDBBadge>
      );
    } else {
      return (
        <MDBBadge pill color="secondary">
          {statusDisplay}
        </MDBBadge>
      );
    }
  }

  toggleActiveNav = (tab) => {
    if (this.state.modalNav !== tab) {
      this.setState({
        modalNav: tab,
      });
    }
  };

  renderForm() {
    const {
      showRecurringBookingModal,
      recurringSchedule,
      recurringCustomer,
      recurringData,
    } = this.state;
    if (showRecurringBookingModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return (
      <div>
        <MDBModal
          isOpen={showRecurringBookingModal}
          size="xl"
          wrapClassName="scrollable"
          toggle={() => this.showRecurringModal(false, null)}
        >
          <MDBModalHeader>Generated recurring bookings</MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol md="3" className="border-right">
                <MDBTable responsive striped bordered>
                  <MDBTableHead>
                    <tr>
                      <th>Booking Information</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    <tr>
                      <td>
                        <strong>Name:</strong>{" "}
                        {recurringCustomer.first_name +
                          " " +
                          recurringCustomer.last_name}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Mobile:</strong> {recurringCustomer.mobile}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Email:</strong> {recurringCustomer.email}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Start Date:</strong> {recurringData.start_date}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>End Date:</strong>
                        {recurringData.endDate}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Total recurrings:</strong>
                        {recurringSchedule.total_count}
                      </td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </MDBCol>
              <MDBCol md="9" style={{ position: "relative" }}>
                <MDBNav className="nav-tabs">
                  <>
                    <MDBNavItem>
                      <MDBNavLink
                        link
                        to="#"
                        active={this.state.modalNav === `blocked_dates_nav`}
                        onClick={() => {
                          this.toggleActiveNav(`blocked_dates_nav`);
                          this.doGetRecurringRecord(
                            this.state.recurringData?.id,
                            1,
                            "blocked_dates"
                          );
                        }}
                        role="tab"
                      >
                        Blocked Dates
                      </MDBNavLink>
                    </MDBNavItem>

                    <MDBNavItem>
                      <MDBNavLink
                        link
                        to="#"
                        active={this.state.modalNav === `available_dates_nav`}
                        onClick={() => {
                          this.toggleActiveNav(`available_dates_nav`);
                          this.doGetRecurringRecord(
                            this.state.recurringData?.id,
                            1,
                            "available_dates"
                          );
                        }}
                        role="tab"
                      >
                        Available Dates
                      </MDBNavLink>
                    </MDBNavItem>
                  </>
                </MDBNav>

                {this.state.modalNav === "available_dates_nav" && (
                  <>
                    {(recurringData.end_date == "" ||
                      recurringData.end_date == null) && (
                      <MDBRow className="my-2">
                        <MDBCol md="4">
                          {" "}
                          <DatePicker
                            className="custom-input"
                            placeholderText="Select Start Date"
                            selected={this.state.unblockTimeSlotStartDate}
                            onChange={(date) => {
                              this.setState({
                                unblockTimeSlotStartDate: date,
                              });
                            }}
                            dateFormat="dd/MM/yyyy"
                          />
                        </MDBCol>
                        <MDBCol md="4">
                          <DatePicker
                            className="custom-input"
                            placeholderText="Select End Date"
                            selected={this.state.unblockTimeSlotEndDate}
                            onChange={(date) => {
                              this.setState({
                                unblockTimeSlotEndDate: date,
                              });
                            }}
                            dateFormat="dd/MM/yyyy"
                          />
                        </MDBCol>
                        <MDBCol md="4">
                          <MDBBtn
                            className="btn-block"
                            size="md"
                            color="secondary"
                            onClick={() => {
                              this.doUnblockRecurringSchedule();
                            }}
                          >
                            Unblock Dates
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    )}
                  </>
                )}
                <MDBTable responsive striped bordered>
                  <MDBTableHead>
                    <tr>
                      <th></th>
                      <th>Booking Reference</th>
                      <th>Status</th>
                      <th>Date</th>
                      <th>Court/Field</th>
                      <th> </th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {recurringSchedule?.data?.map((schedule) => {
                      return (
                        <tr>
                          <td>
                            {!["cancelling", "cancelled", "available"].includes(
                              schedule.status
                            ) && (
                              <Checkbox
                                type="checkbox"
                                // onChange={(e) => handleOnChange(e.target.checked)}
                                onChange={(e) => {
                                  this.toggleSelectedRecurringSchedule(
                                    schedule.id
                                  );
                                }}
                              />
                            )}
                          </td>
                          <td>{schedule.booking?.booking_reference}</td>
                          <td>
                            {this.renderRecurringSchedulesStatus(
                              schedule.status
                            )}
                          </td>
                          <td>
                            {moment(schedule["booking_from"])
                              .tz("Australia/Sydney")
                              .format("DD/MM/YYYY")}{" "}
                            <br />
                            {moment(schedule["booking_from"])
                              .tz("Australia/Sydney")
                              .format("HH:mm")}
                            {" - "}
                            {moment(schedule["booking_to"])
                              .tz("Australia/Sydney")
                              .format("HH:mm")}{" "}
                            <br />
                            {moment(schedule["booking_from"])
                              .tz("Australia/Sydney")
                              .format("dddd")}{" "}
                          </td>
                          <td>{recurringData.resource?.name}</td>
                          <td>
                            <>
                              {schedule.booking?.uuid && (
                                <MDBBtn
                                  color="primary"
                                  size="sm"
                                  className="float-right"
                                  onClick={() => {
                                    this.props.showHideLoader(true);
                                    this.props.getBookingDetails(
                                      schedule.booking.uuid
                                    );
                                  }}
                                >
                                  More Details
                                </MDBBtn>
                              )}{" "}
                              {schedule.status == "processing" && (
                                <MDBBtn
                                  color="success"
                                  size="sm"
                                  className="float-right"
                                  onClick={() =>
                                    this.confirmReprocess(recurringData)
                                  }
                                >
                                  Reprocess
                                </MDBBtn>
                              )}
                            </>
                          </td>
                        </tr>
                      );
                    })}
                  </MDBTableBody>
                </MDBTable>
                <div className="float-right">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={recurringSchedule.total_pages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={4}
                    onPageChange={(e) => {
                      let page = e.selected + 1;
                      this.doGetRecurringRecord(recurringData["id"], page);
                    }}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            {this.state.modalNav === `blocked_dates_nav` && (
              <MDBBtn
                color="danger"
                className={
                  this.state.selectedCancelSchedules.length == 0
                    ? "disabled"
                    : ""
                }
                onClick={() => {
                  this.doCancelletaion();
                }}
              >
                Cancel Selected
              </MDBBtn>
            )}

            <MDBBtn
              color="secondary"
              onClick={() => {
                this.showRecurringModal(false, null);
                this.debouncedDoFilter();
              }}
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </div>
    );
  }

  renderTable() {
    const { resultContainer, page } = this.state;
    const { storeUserAccess } = this.props;
    const data = {
      columns: [
        {
          label: "",
          field: "recurring_id",
          sort: "asc",
        },
        {
          label: "Customer",
          field: "customer",
          sort: "asc",
        },
        {
          label: "Recurring Type",
          field: "recurring_type",
          sort: "asc",
        },
        {
          label: "Start Date",
          field: "startDate",
          sort: "asc",
        },
        {
          label: "End Date",
          field: "endDate",
          sort: "asc",
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
        },
        {
          label: "Court/Field",
          field: "court_field",
          sort: "asc",
        },
        {
          label: "Actions",
          field: "buttons",
          sort: "asc",
        },
      ],
    };

    let rows = [];
    if (typeof resultContainer.results !== "undefined") {
      rows = _.chain(resultContainer.results)
        .map((result, index) => {
          let startDate = moment(result.start_date + "T13:00:00+00:00")
            .local()
            .format("DD/MM/YYYY");
          let endDate = result.end_date;

          if (
            endDate == null ||
            endDate == "" ||
            endDate == "None" ||
            endDate == "0000-00-00"
          ) {
            endDate = "Indefinite";
          } else {
            endDate = moment(endDate + "T13:00:00+00:00")
              .local()
              .format("DD/MM/YYYY");
          }

          let customer = result.customer;

          return {
            recurring_id: result.id,
            customer: (
              <span>
                {customer.first_name} {customer.last_name}
                <br></br>
                {customer.mobile}
                <br></br>
                {customer.email}
                <br></br>
              </span>
            ),
            recurring_type: result.recurring_type,
            startDate: startDate,
            endDate: endDate,
            status: result.status,
            court_field: result.resource?.name,
            buttons: (
              <div>
                {result.status == "Active" &&
                result.booking_request_obj != undefined &&
                validateAccess(
                  storeUserAccess,
                  "RecurringBookings",
                  "cancelRecurring"
                ) ? (
                  <MDBBtn
                    color="danger"
                    size="sm"
                    className="float-right"
                    onClick={() => this.confirmCancellation(result)}
                  >
                    Cancel Recurring
                  </MDBBtn>
                ) : (
                  <span></span>
                )}
                {validateAccess(
                  storeUserAccess,
                  "RecurringBookings",
                  "viewBookings"
                ) && (
                  <MDBBtn
                    style={{
                      marginTop: ".375rem",
                      marginBottom: ".375rem",
                      marginRight: ".375rem",
                      marginLeft: ".375rem",
                    }}
                    //href={bookingUrl}
                    onClick={() => this.showRecurringModal(true, result)}
                    color="primary"
                    size="sm"
                    className="float-right"
                  >
                    View Bookings
                  </MDBBtn>
                )}

                {/* {result.is_processing == 1 && (
                  <MDBBtn
                    color="success"
                    size="sm"
                    className="float-right"
                    onClick={() => this.confirmReprocess(result)}
                  >
                    Reprocess
                  </MDBBtn>
                )} */}
              </div>
            ),
          };
        })
        .value();
      data["rows"] = rows;
      return (
        <MDBContainer>
          {this.renderFilters()}
          <MDBTable responsive striped bordered>
            <MDBTableHead columns={data.columns} />
            <MDBTableBody rows={data.rows} />
          </MDBTable>
          <MDBRow>
            <MDBCol md="12">
              {this.renderTotalResult()}
              <div className="float-right">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={resultContainer.totalPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={4}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      );
    }
    return <div></div>;
  }

  doUnblockRecurringSchedule = async () => {
    const { recurringData, unblockTimeSlotStartDate, unblockTimeSlotEndDate } =
      this.state;

    this.props.showHideLoader(true);
    let requestBody = {
      start_date: moment(unblockTimeSlotStartDate).format("YYYY-MM-DD"),
      end_date: moment(unblockTimeSlotEndDate).format("YYYY-MM-DD"),
    };
    let response = await unblockRecurringSchedule(
      recurringData.id,
      requestBody
    );

    console.log(response);
    if (response) {
      this.props.showHideLoader(false);

      switch (response.status) {
        case 200:
        case 201:
          this.props.showNotification("success", response.data.message);
          break;

        default:
          this.props.showNotification("error", response.data.message);
          break;
      }
    } else {
      this.props.showNotification(
        "error",
        "Error encountered. Please contact administrator."
      );
      this.props.showHideLoader(false);
    }
  };
  doReprocessRecurring = async () => {
    const { recurringData } = this.state;

    this.props.showHideLoader(true);
    let response = await reprocessRecurring(recurringData.id);

    if (response) {
      this.props.showHideLoader(false);

      switch (response.status) {
        case 200:
        case 201:
          this.props.showNotification("success", response.data.message);
          break;

        default:
          this.props.showNotification("error", response.data.message);
          break;
      }
    } else {
      this.props.showNotification(
        "error",
        "Error encountered. Please contact administrator."
      );
      this.props.showHideLoader(false);
    }

    this.closeModal();
  };

  doCancelletaion = async () => {
    const { recurringData, selectedCancelSchedules } = this.state;
    this.props.showHideLoader(true);
    let requestBody = {
      selected_recurring_schedules: selectedCancelSchedules,
    };

    let response = await cancelRecurring(recurringData.id, requestBody);

    if (response) {
      this.props.showHideLoader(false);

      switch (response.status) {
        case 200:
        case 201:
          this.props.showNotification("success", response.data.message);
          break;

        default:
          this.props.showNotification("error", response.data.message);
          break;
      }
    } else {
      this.props.showNotification(
        "error",
        "Error encountered. Please contact administrator."
      );
      this.props.showHideLoader(false);
    }

    this.closeModal();
  };

  doGetRecurringRecord = async (
    recurring_id,
    page = 1,
    blocking_type = "blocked_dates"
  ) => {
    this.props.showHideLoader(true);

    let searchParams = {
      limit: 10,
      page: page,
      blocking_type: blocking_type,
    };

    let response = await getRecurringById(recurring_id, searchParams);

    if (response) {
      this.props.showHideLoader(false);

      switch (response.status) {
        case 200:
        case 201:
          let recurring_data = response.data.data;

          this.setState({
            recurringSchedule: recurring_data.recurring_schedules,
            recurringCustomer: recurring_data.customer,
            recurringData: recurring_data,
          });
          break;

        default:
          this.props.showNotification("error", response.data.message);
          break;
      }
    } else {
      this.props.showNotification(
        "error",
        "Error encountered. Please contact administrator."
      );
      this.props.showHideLoader(false);
    }

    this.closeModal();
  };

  closeModal() {
    const modal = {
      isOpen: false,
    };
    this.props.toggleModal(modal);
  }

  confirmReprocess = (recurring) => {
    const modal = {
      isOpen: true,
      content: <span>This will reprocess all pending recurrings</span>,
      title: "Reprocess Recurring Booking",
      negativeButtonText: "Cancel",
      positiveButtonText: "Proceed",
      positiveButtonColor: "success",
      negativeButtonAction: this.closeModal.bind(this),
      positiveButtonAction: this.doReprocessRecurring.bind(this),
    };

    this.props.toggleModal(modal);

    this.setState({ recurringData: recurring });
  };

  confirmCancellation = (recurring) => {
    const modal = {
      isOpen: true,
      content: (
        <span>
          Are you sure you want to cancel recurring? Any pending bookings will
          be cancelled.
        </span>
      ),
      title: "Recurring Cancellation Confirmation.",
      negativeButtonText: "Cancel",
      positiveButtonText: "Proceed",
      positiveButtonColor: "danger",
      negativeButtonAction: this.closeModal.bind(this),
      positiveButtonAction: this.doCancelletaion.bind(this),
    };

    this.props.toggleModal(modal);

    this.setState({ recurringData: recurring });
  };

  getValueOfSelectLimit = (value) => {
    this.setState({ limit: value.length > 0 ? value[0] : "" });
    this.debouncedDoFilter();
  };

  renderTotalResult() {
    const { resultContainer } = this.state;
    if (typeof resultContainer.recurring_bookings !== "undefined") {
      return (
        <span>
          Showing{" "}
          {resultContainer.recurring_bookings.limit *
            resultContainer.recurring_bookings.page}{" "}
          of {resultContainer.recurring_bookings.total} result
        </span>
      );
    }
    return <span></span>;
  }

  doBookingFilter() {
    const { parentBookingId, page, limit, query } = this.state;
    this.props.showHideLoader(true);
    let params = {};
    if ((page + "").length > 0) {
      params["page"] = page;
    }
    if (limit.length > 0) {
      params["limit"] = limit;
    }
    if (parentBookingId !== undefined) {
      params["parentBookingId"] = parentBookingId;
    }

    this.props.getBookings(params, this.props.authUser.sessionToken);
  }

  initStatusOptions() {
    let options = [
      {
        checked: true,
        disabled: false,
        text: "Active",
        value: "Active",
      },
      {
        checked: false,
        disabled: false,
        text: "Inactive",
        value: "Inactive",
      },
    ];

    this.setState({ statusOptions: options });
  }

  doFilter() {
    const { startDateFilter, endDateFilter, status, page, limit, query } =
      this.state;

    this.props.showHideLoader(true);

    let params = {};
    if (startDateFilter) {
      params["startDate"] = moment(startDateFilter).utc().format("YYYY-MM-DD");
    }

    if (endDateFilter) {
      params["endDate"] = moment(endDateFilter).utc().format("YYYY-MM-DD");
    }

    if (status !== undefined && status.length > 0) {
      params["status"] = status;
    }

    if ((page + "").length > 0) {
      params["page"] = page;
    }

    if (limit.length > 0) {
      params["limit"] = limit;
    }

    if (query !== undefined) {
      params["query"] = query;
    }

    this.props.getRecurringBookings(params, this.props.authUser.sessionToken);
  }

  changeHandler = (event) => {
    clearTimeout(this.state.timer);
    let timer = setTimeout(() => {
      this.debouncedDoFilter();
    }, 500);

    this.setState({
      ...this.state,
      timer: timer,
      [event.target.name]: event.target.value,
    });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    this.setState({
      page: selectedPage,
    });
    this.debouncedDoFilter();
  };
}

const mapStateToProps = (state) => {
  return {
    authUser: state.authUser,
    recurringBookingResponse: state.recurringBookingResponse,
    getBookingsSuccessResponse: state.getBookingsSuccessResponse,
    storeUserAccess: state.storeUserAccess,
    bookingDetailsResponse: state.bookingDetailsResponse,
    getStatusesSuccessResponse: state.getStatusesSuccessResponse,
    getResourceResponse: state.resourceResponse,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    displayBreadcrumbs,
    showHideLoader,
    getRecurringBookings,
    getBookings,
    toggleModal,
    showNotification,
    getBookingDetails,
    getStatuses,
    getResource,
  })(ManageRecurring)
);
